import React from 'react'
import PropTypes from 'prop-types'

import Hero from '../components/Hero'

export default function BookADemoHero({ image }) {
  return (
    <>
      <Hero
        mainTitle="join the change today!"
        imgSrc={image}
        subtitle="Reach out to us to witness the product in action."
        buttons={['demo']}
      />
      <div className="my-12" />
    </>
  )
}

BookADemoHero.propTypes = {
  image: PropTypes.shape()
}
