import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import AnimatedLinkComponent from './AnimatedLink'

const buttonContent = [
  {
    name: 'demo',
    text: 'book a demo',
    url: '/book-a-demo'
  },
  {
    name: 'video',
    text: 'watch the video',
    url: '/'
  },
  {
    name: 'story',
    text: 'discover our story',
    url: '/our-story'
  },
  {
    name: 'innovations',
    text: 'explore our innovations',
    url: '/innovation'
  },
  {
    name: 'success',
    text: 'see more success stories',
    url: '/partners'
  }
]

const StyledText = styled.h1`
  position: absolute;
  color: white;
  text-transform: uppercase;
  bottom: 10%;
  width: auto;
  @media(max-width: 767px) {
    left: 5%;
  }
  left: 20%;
  text-align: left;
  margin: 0 auto;
  padding: 5%;
  border: 0.2rem solid #FADB31;
  background: rgba(0, 0, 0, 0.5);
`

const StyledButton = styled.button`
  text-transform: uppercase;
  color: #000;
  font-family: 'FF DIN Pro Medium';
  margin: 0.25rem;
  padding: 0.25rem;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  border: 4px solid #000;
  background: linear-gradient(to right, black 50%, white 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all 0.5s ease-out;
  :hover {
    background-position: left bottom;
    color: #FADB31;
  }
`

const StyledContentDiv = styled.div`
  @media(min-width: 768px) {
    border: 0.4rem solid #FADB31;
    padding-right: ${props => (!props.right ? '8rem' : '5rem')};
    padding-left: ${props => (props.right ? '8rem' : '5rem')};
    margin-right: ${props => (!props.right ? '-4rem' : '-8rem')};
    margin-left: ${props => (props.right ? '-4rem' : '-8rem')};
    padding-top: 1rem;
    padding-bottom: 1rem;
    width: 140%;
    min-height: 10rem;
    z-index: 2;
    left: ${props => (!props.right ? null : '-4rem')};
    right: -4rem;
    position: relative;
    /* h2 { TODO: needs work
      position: absolute;
      font-family: 'FF DIN Pro Bold';
      color: #fff;
      transform: rotate(-90deg);
      top: 45%;
      left: ${props => (!props.right ? '-10rem' : null)};
      right: ${props => (!props.right ? null : '-4rem')};
    } */
  }
`

const InsaneStyledTitle = styled.h2`
  position: absolute;
  right: -3rem;
  top: 50%;
  bottom: 50%;
  transform: rotate(270deg);
  z-index: 1;
  color: white;
`

export default function Hero({
  imgSrc,
  title,
  subtitle,
  trailingText,
  buttons,
  right,
  full,
  mainTitle,
  children
}) {
  let mainDiv = 'w-full md:flex md:items-center md:w-11/12 md:mx-auto'
  const mainTitleClassName = 'uppercase font-medium text-xl mb-4'
  let contentClassName = 'mx-auto mb-8 px-8'
  const imgDivClassName = full ? 'relative w-full mb-4' : 'relative w-175 mb-4'
  let imgClassName = 'w-full md:rounded-lg md:shadow-2xl'

  if (full) {
    imgClassName = 'w-full lg:rounded-lg lg:shadow-lg'
    mainDiv = 'lg:w-11/12 lg:mx-auto'
    contentClassName = 'mb-8 w-full px-8'
  }

  if (right) {
    imgClassName = imgClassName + ''
    contentClassName = contentClassName + ''
    mainDiv = mainDiv + ' flex-row-reverse'
  }

  if (!title && !subtitle && !trailingText) {
    return (
      <div className={imgDivClassName}>
        <Img
          fluid={imgSrc.childImageSharp.fluid}
          alt={title || 'workwear image'}
          className={imgClassName}
        />
      </div>
    )
  }

  const [clicked, set] = useState(false)

  const animations = useSpring({
    filter: `opacity(${!clicked ? 100 : 95}%) saturate(${!clicked ? 100 : 105}%)`,
    config: { mass: 5, tension: 500, friction: 80 }
  })

  return (
    <animated.div
      className={mainDiv}
      style={animations}
      onClick={() => set(state => !state)}
    >
      <div className={imgDivClassName}>
        {children && children}
        {!children && (
          <Img
            fluid={imgSrc.childImageSharp.fluid}
            alt={title || 'workwear image'}
            className={imgClassName}
          />
        )}
        {title && (
          <StyledText className="font-medium uppercase text-lg md:text-2xl">
            <span>{title}</span>
          </StyledText>
        )}
      </div>
      {!full ? (
        <StyledContentDiv right={right} className={contentClassName}>
          <h1 className={mainTitleClassName}>{mainTitle}</h1>
          {subtitle && (
            <p className="mb-4 leading-snug">{subtitle}</p>
          )}
          {trailingText && (
            <p className="mb-6 leading-snug">{trailingText}</p>
          )}
          <div className="inline-block">
            {buttons &&
              buttons.map(button => {
                const matchingButton = buttonContent.find(
                  b => b.name === button
                )

                return (
                  <AnimatedLinkComponent key={button} href={matchingButton.url}>
                    <StyledButton>{matchingButton.text}</StyledButton>
                  </AnimatedLinkComponent>
                )
              })}
          </div>
        </StyledContentDiv>
      ) : (
        <div right={right} className={contentClassName}>
          <h1 className={mainTitleClassName}>{mainTitle}</h1>
          {subtitle && (
            <p className="mb-4 leading-snug">{subtitle}</p>
          )}
          {trailingText && (
            <p className="mb-6 leading-snug">{trailingText}</p>
          )}
          <div className="inline-block">
            {buttons &&
              buttons.map(button => {
                const matchingButton = buttonContent.find(
                  b => b.name === button
                )

                return (
                  <AnimatedLinkComponent key={button} href={matchingButton.url}>
                    <StyledButton>{matchingButton.text}</StyledButton>
                  </AnimatedLinkComponent>
                )
              })}
          </div>
        </div>
      )}
    </animated.div>
  )
}

Hero.propTypes = {
  imgSrc: PropTypes.object,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  trailingText: PropTypes.node,
  right: PropTypes.bool,
  full: PropTypes.bool,
  mainTitle: PropTypes.string,
  buttons: PropTypes.array,
  children: PropTypes.node
}
